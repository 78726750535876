import { useStore, useStoreMap } from 'effector-react'

import { Form, inspectionModel } from '~/entities/Inspection'
import { incidentResponsibleEnumLabels } from '~/shared/config/constants'
import { getDuration } from '~/shared/lib/date'
import {
  mapApiDocumentsToFormDocuments,
  mapApiEquipmentsToFormEquipments,
  mapApiServicesToFormServices,
  mapApiWheelsToFormWheels,
} from './mappers'

export function Details() {
  const isLoading = useStore(inspectionModel.requestInspectionFx.pending)

  const defaultValues = useStoreMap(
    inspectionModel.$inspection,
    (inspection) => {
      const incidentResponsible = inspection?.getIncidentResponsible()
      return {
        ...inspection?.getAttributes(),
        vin: inspection?.getCar()?.getVin(),
        carBrandModel: inspection?.getCar()?.getBrandModel()?.getTitle(),
        reason: inspection?.getReason()?.getTitle(),
        reasonCode: inspection?.getReason()?.getCode(),
        responsible: inspection?.getResponsible().getName(),
        duration: getDuration(inspection?.getDuration()),
        documents: mapApiDocumentsToFormDocuments(inspection?.getDocuments()),
        equipments: mapApiEquipmentsToFormEquipments(
          inspection?.getEquipments(),
        ),
        services: mapApiServicesToFormServices(inspection?.getServices()),
        wheels: mapApiWheelsToFormWheels(inspection?.getWheelsStates()),
        incidentResponsible: incidentResponsible
          ? incidentResponsibleEnumLabels[incidentResponsible]
          : '',
        refundReasons: inspection?.getRefundReasons()?.join(', '),
      }
    },
  )

  const carId = useStoreMap(inspectionModel.$inspection, (inspection) =>
    inspection?.getCar()?.getExtId(),
  )
  const driverExtId = useStoreMap(inspectionModel.$inspection, (inspection) =>
    inspection?.getDriverExtId(),
  )

  return (
    <Form
      isLoading={isLoading}
      defaultValues={defaultValues}
      carId={carId}
      driverExtId={driverExtId}
    />
  )
}
