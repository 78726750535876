import { GridColDef } from '@mui/x-data-grid'
import React from 'react'
import { TMS_DOMAIN } from '~/shared/config/env'
import { ColumnDate, ColumnLink } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { FileExportLink } from '~/shared/ui/FileExport'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Дата',
    field: 'updatedAt',
    minWidth: 160,
    renderCell: ({ row, value }) => (
      <ColumnLink to={`${row.id}/details`}>
        <ColumnDate type='date' value={value} />
      </ColumnLink>
    ),
  },
  {
    headerName: 'Автомобиль',
    field: 'plateNumber',
    minWidth: 160,
    renderCell: ({ row, value }) => (
      <ColumnLink href={`${TMS_DOMAIN}cars/${row.carExtId}`}>
        {value}
      </ColumnLink>
    ),
  },
  {
    headerName: 'Марка Модель автомобиля',
    field: 'carBrandModel',
    minWidth: 210,
    sortable: false,
  },
  {
    headerName: 'Пробег',
    field: 'mileage',
    minWidth: 120,
  },
  {
    headerName: 'Причина осмотра',
    field: 'reason',
    sortable: false,
    minWidth: 200,
  },
  {
    headerName: 'ФИО Водителя',
    field: 'driverFullName',
    minWidth: 200,
    renderCell: ({ row, value }) =>
      row.driverExtId ? (
        <ColumnLink href={`${TMS_DOMAIN}drivers/${row.driverExtId}`}>
          {value}
        </ColumnLink>
      ) : (
        value
      ),
  },
  {
    headerName: 'Наличие повреждений',
    field: 'hasDamages',
    minWidth: 190,
    sortable: false,
    renderCell: ({ value }) => (
      <ChipStatus
        variant={value ? 'green' : 'red'}
        label={value ? 'Да' : 'Нет'}
      />
    ),
  },
  {
    headerName: 'Сумма списаний',
    field: 'totalCost',
    minWidth: 160,
    sortable: false,
    renderCell: ({ value }) => (value || 0).toLocaleString(),
  },
  {
    headerName: 'Наличие фото кругового осмотра',
    field: 'hasCarPhotos',
    minWidth: 250,
    sortable: false,
    renderCell: ({ value }) => (
      <ChipStatus
        variant={value ? 'green' : 'red'}
        label={value ? 'Да' : 'Нет'}
      />
    ),
  },
  {
    headerName: 'Утеря документов',
    field: 'hasDocumentsLoss',
    minWidth: 160,
    sortable: false,
    renderCell: ({ value }) => (
      <ChipStatus
        variant={value ? 'green' : 'red'}
        label={value ? 'Да' : 'Нет'}
      />
    ),
  },
  {
    headerName: 'ФИО механика',
    field: 'mechanicFullName',
    minWidth: 200,
    sortable: false,
  },
  {
    headerName: 'Комментарий',
    field: 'comment',
    minWidth: 200,
    sortable: false,
  },
  {
    headerName: 'Регион',
    field: 'regionTitle',
    minWidth: 160,
    sortable: false,
  },
  {
    headerName: 'Подразделение',
    field: 'subdivisionTitle',
    minWidth: 160,
    sortable: false,
  },
  {
    headerName: 'Действия',
    field: 'actions',
    minWidth: 120,
    sortable: false,
    renderCell: ({ row }) => {
      return (
        row?.inspectionReport?.originalUrlDownload && (
          <FileExportLink
            tooltipText='Скачать АКТ осмотра'
            filepath={row?.inspectionReport?.originalUrlDownload}
          />
        )
      )
    },
  },
]
