import { Grid } from '@mui/joy'
import { FormTitle, Switch } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

export function Safety() {
  return (
    <Container>
      <FormTitle>Служба безопасности</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <Switch
            label='Водителю требуется пройти службу безопасности'
            name='isDriverNeedsSentToSecurity'
            readOnly
          />
        </Grid>
        <Grid xs={6}>
          <Switch
            label='Водитель направлен на проверку службы безопасности'
            name='isDriverWasSentToSecurity'
            readOnly
          />
        </Grid>
      </Grid>
    </Container>
  )
}
