import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { Details } from '~/features/Inspection/Details'
import { inspectionModel } from '~/entities/Inspection'
import { InspectionReasonCodeEnum } from '~/shared/config/enums'
import { canShowObject } from '~/shared/lib/utils'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

export function DetailsPage() {
  const reasonCode = useStoreMap(inspectionModel.$inspection, (inspection) =>
    inspection?.getReason()?.getCode(),
  )
  const isLoading = useStore(inspectionModel.requestInspectionFx.pending)

  const quickNavigationOptions = useMemo(
    () => [
      ...canShowObject(
        !isLoading && reasonCode === InspectionReasonCodeEnum.REFUND,
        {
          label: 'Служба безопасности',
          anchor: 'safety',
        },
      ),
      { label: 'Осн. информация', anchor: 'main' },
      { label: 'Состояние', anchor: 'state' },
      { label: 'Документы', anchor: 'documents' },
      { label: 'Комплектность', anchor: 'equipments' },
      { label: 'Обслуживание', anchor: 'service' },
      { label: 'Колеса', anchor: 'wheels' },
      { label: 'Фото', anchor: 'photos' },
    ],
    [isLoading, reasonCode],
  )

  return (
    <>
      <Details />

      <QuickNavigation options={quickNavigationOptions} />
    </>
  )
}
