import { useGate } from 'effector-react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Element } from 'react-scroll'
import { Safety } from '~/entities/Inspection/ui/Form/Safety'
import { InspectionReasonCodeEnum } from '~/shared/config/enums'
import { FormProvider } from '~/shared/ui/Form'
import { Documents } from './Documents'
import { Equipments } from './Equipments'
import { Main } from './Main'
import { FormValues } from './model'
import { Gate } from './model'
import { Photos } from './Photos'
import { Service } from './Service'
import { State } from './State'
import { Wheels } from './Wheels'

type FormProps = {
  isLoading: boolean
  defaultValues?: Partial<FormValues>
  carId?: UniqueId
  driverExtId?: UniqueId
}

export function Form({
  isLoading,
  defaultValues,
  carId,
  driverExtId,
}: FormProps) {
  useGate(Gate)

  const form = useForm<FormValues>({
    defaultValues,
  })
  const { watch } = form

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  const photos = useMemo(
    () => defaultValues?.photos || [],
    [defaultValues?.photos],
  )

  const reasonCode = watch('reasonCode')
  const isRefund = reasonCode === InspectionReasonCodeEnum.REFUND

  return (
    <FormProvider form={form}>
      {!isLoading && isRefund && (
        <Element name='safety'>
          <Safety />
        </Element>
      )}
      <Element name='main'>
        <Main carId={carId} driverExtId={driverExtId} loading={isLoading} />
      </Element>
      <Element name='state'>
        <State loading={isLoading} />
      </Element>
      <Element name='documents'>
        <Documents loading={isLoading} />
      </Element>
      <Element name='equipments'>
        <Equipments loading={isLoading} />
      </Element>
      <Element name='service'>
        <Service loading={isLoading} />
      </Element>
      <Element name='wheels'>
        <Wheels loading={isLoading} />
      </Element>
      <Element name='photos'>
        <Photos photos={photos} loading={isLoading} />
      </Element>
    </FormProvider>
  )
}
